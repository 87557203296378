
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import { influencer_content_creating_experience, influencer_platform_primary_goals, creator_next_steps, } from '@/core/common/steps';
import axios from "@/core/services/api";


export default defineComponent({
    name: "InfluencerOnboardingQuestions",
    emits: ['close-modal'],
    components: {
    },
    props: {
        is_modal_open: {
            type: Boolean,
            required: true
        },
    },
    setup(props, { emit }) {

        const toaster = createToaster();
        const store = useStore();
        const userInfo = computed(() => store.state.global.userInfo);
        const isLoading = ref(false);
        const isOtherGoal = ref(false);
        const otherGoal = ref('');

        const isOtherObjective = ref(false);
        const otherObjective = ref('');

        const isOtherOccupation = ref(false);
        const otherOccupation = ref('');

        const isOtherChallange = ref(false);
        const otherChallange = ref('');

        const step = ref(1);
        const formData = ref({
            advertiser_id: userInfo.value.advertiser_id,
            influencer_id: userInfo.value.influencer_id,
            role: userInfo.value.role,
            use_goals: [] as any,
            campaign_objectives: [] as any,
            influencer_marketing_experience: '',
            status: 'Skipped',
            isLegacy: userInfo.value.influencer_id
        });

        const selectGoal = (option: string) => {
            if (option != 'Other') {
                if (formData.value.use_goals.some((item: any) => item.label == option) == false) {
                    formData.value.use_goals.push({
                        value: option,
                        label: option
                    })
                } else {
                    const the_index = formData.value.use_goals.findIndex((item: any) => item.label == option)
                    formData.value.use_goals.splice(the_index, 1)
                }
            } else {
                isOtherGoal.value = true
                formData.value.use_goals.push({
                    value: option,
                    label: ''
                })
            }
        }

        const selectObjective = (option: string) => {
            if (option != 'Other') {
                if (formData.value.campaign_objectives.some((item: any) => item.label == option) == false) {
                    formData.value.campaign_objectives.push({
                        value: option,
                        label: option
                    })
                } else {
                    const the_index = formData.value.campaign_objectives.findIndex((item: any) => item.label == option)
                    formData.value.campaign_objectives.splice(the_index, 1)
                }
            } else {
                isOtherObjective.value = true
                formData.value.campaign_objectives.push({
                    value: option,
                    label: ''
                })
            }
        }

        const selectMarketingExperience = (option: string) => {
            formData.value.influencer_marketing_experience = option
        }


        const onboardingStatus = (): string => {
            let status = 'Skipped'
            if (
                formData.value.use_goals?.length > 0 &&
                formData.value.campaign_objectives?.length > 0 &&
                formData.value.influencer_marketing_experience != ''
            ) {
                status = 'Filled'
            } else if (
                formData.value.use_goals?.length > 0 ||
                formData.value.campaign_objectives?.length > 0 ||
                formData.value.influencer_marketing_experience != ''
            ) {
                status = 'Partial'
            }

            return status;
        }


        const save = () => {
            if (step.value == 1) {
                const other_goal = formData.value.use_goals.some((item: any) => item.value == 'Other')
                if (other_goal == true) {
                    const other_goal_index = formData.value.use_goals.findIndex((item: any) => item.value == 'Other')
                    formData.value.use_goals[other_goal_index].label = otherGoal.value != '' ? otherGoal.value : 'Other'
                }

                step.value = 2
            } else if (step.value == 2) {
                const other_objective = formData.value.campaign_objectives.some((item: any) => item.value == 'Other')
                if (other_objective == true) {
                    const other_objective_index = formData.value.campaign_objectives.findIndex((item: any) => item.value == 'Other')
                    formData.value.campaign_objectives[other_objective_index].label = otherObjective.value != '' ? otherObjective.value : 'Other'
                }

                formData.value.status = onboardingStatus();

                axios
                    .post(`/user-preferences/create`, formData.value)
                    .then((res) => {
                        if (res.status === 200) {
                            toaster.show("Submitted sucessfully! Thank you for your time!", {
                                type: "info",
                                position: "bottom",
                            });
                            isLoading.value = false;
                            setTimeout(() => {
                                window.location.reload()
                            }, 300);
                        }
                    })
                    .catch(() => {
                        toaster.show("Sorry something gone wrong !", {
                            type: "error",
                            position: "bottom",
                        });
                        isLoading.value = false;
                    });
            }
        }

        const closeModal = () => {

            const other_goal = formData.value.use_goals.some((item: any) => item.value == 'Other')
            if (other_goal == true) {
                const other_goal_index = formData.value.use_goals.findIndex((item: any) => item.value == 'Other')
                formData.value.use_goals[other_goal_index].label = otherGoal.value != '' ? otherGoal.value : 'Other'
            }

            const other_objective = formData.value.campaign_objectives.some((item: any) => item.value == 'Other')
            if (other_objective == true) {
                const other_objective_index = formData.value.campaign_objectives.findIndex((item: any) => item.value == 'Other')
                formData.value.campaign_objectives[other_objective_index].label = otherObjective.value != '' ? otherObjective.value : 'Other'
            }

            formData.value.status = onboardingStatus();
            axios
                .post(`/user-preferences/create`, formData.value)
                .then((res) => {
                    if (res.status === 200) {
                        toaster.show("Thank you for your time!", {
                            type: "info",
                            position: "bottom",
                        });
                        isLoading.value = false;
                        setTimeout(() => {
                            // emit('close-modal', false);
                            window.location.reload()
                        }, 300);
                    }
                })
        }

        return {
            formData,
            closeModal,
            save,
            selectGoal,
            selectObjective,
            selectMarketingExperience,
            isOtherGoal,
            otherGoal,
            isOtherObjective,
            otherObjective,
            isOtherOccupation,
            otherOccupation,
            isOtherChallange,
            otherChallange,
            step,
            influencer_content_creating_experience,
            influencer_platform_primary_goals,
            creator_next_steps,
        };
    },
});




