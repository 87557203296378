import { ref } from "vue";

import StepOne from "@/components/discover-steps/GenderStep.vue";
import StepTwo from "@/components/discover-steps/AgeStep.vue";
import StepFour from "@/components/discover-steps/ResidenceStep.vue";
import StepFive from "@/components/discover-steps/FollowersCountStep.vue";

export const sortFilters = [
    {
        id: 'd.followers_count',
        text: 'Followers Count'
    },
    {
        id: 'd.average_video_views',
        text: 'Average video views'
    },
    {
        id: 'd.engagement_rate',
        text: 'Engagement Rate'
    }
]

export const steps = [
    {
        title: "Gender",
        subtitle: "Subtitle sample",
        component: StepOne
    },
    {
        title: "Age",
        subtitle: "Subtitle sample",
        component: StepTwo,
        completed: false
    },
    {
        title: "Location",
        subtitle: "Subtitle sample",
        component: StepFour,
        completed: false
    },
    {
        title: "Followers Count",
        subtitle: "Subtitle sample",
        component: StepFive,
        completed: false
    },
]

export const countries = [
    {
        label: 'Tunisia',
        value: 'TN',
        icon: 'tunisia'
    },
    {
        label: 'France',
        value: 'FR',
        icon: 'france'
    },
    {
        label: 'Egypt',
        value: 'EG',
        icon: 'egypt'
    },
    {
        label: 'Algeria',
        value: 'DZ',
        icon: 'algeria'
    },
    {
        label: 'Morroco',
        value: 'MA',
        icon: 'morroco'
    },
    {
        label: 'UAE',
        value: 'AE',
        icon: 'uae'
    },
    {
        label: 'Saudi Arabia',
        value: 'SA',
        icon: 'sa'
    },
    {
        label: 'UK',
        value: 'GB',
        icon: 'uk'
    },
    {
        label: 'Germany',
        value: 'DE',
        icon: 'germany'
    },
    {
        label: 'Italy',
        value: 'IT',
        icon: 'italy'
    },
    {
        label: 'USA',
        value: 'US',
        icon: 'usa'
    },
    {
        label: 'Lebanon',
        value: 'LB',
        icon: 'lebanon'
    },
    {
        label: 'Turkey',
        value: 'TR',
        icon: 'turkey'
    },
    {
        label: 'China',
        value: 'CN',
        icon: 'china'
    },
    {
        label: 'India',
        value: 'IN',
        icon: 'india'
    },
    {
        label: 'Brazil',
        value: 'BR',
        icon: 'brazil'
    },
    {
        label: 'Argentina',
        value: 'AR',
        icon: 'argentina'
    },
    {
        label: 'Qatar',
        value: 'QA',
        icon: 'qatar'
    }
]

export const countriesPrefixes = [
    {
        label: '216',
        value: 216,
        icon: 'tunisia',
    },
    {
        label: '33',
        value: 33,
        icon: 'france',
    },
    {
        label: '20',
        value: 20,
        icon: 'egypt',
    },
    {
        label: '213',
        value: 213,
        icon: 'algeria',
    },
    {
        label: '212',
        value: 212,
        icon: 'morroco',
    },
    {
        label: '971',
        value: 971,
        icon: 'uae',
    },
    {
        label: '966',
        value: 966,
        icon: 'sa',
    },
    {
        label: '44',
        value: 44,
        icon: 'uk',
    },
    {
        label: '49',
        value: 49,
        icon: 'germany',
    },
    {
        label: '39',
        value: 39,
        icon: 'italy',
    },
    {
        label: '1',
        value: 1,
        icon: 'usa',
    },
    {
        label: '961',
        value: 961,
        icon: 'lebanon',
    },
    {
        label: '90',
        value: 90,
        icon: 'turkey',
    },
    {
        label: '86',
        value: 86,
        icon: 'china',
    },
    {
        label: '91',
        value: 91,
        icon: 'india',
    },
    {
        label: '55',
        value: 55,
        icon: 'brazil',
    },
    {
        label: '54',
        value: 54,
        icon: 'argentina',
    }
];

export const genders = ref([
    {
        value: 'F',
        label: 'Female',
        icon: 'female',
        class: 'female-btn'
    },
    {
        value: 'M',
        label: 'Male',
        icon: 'male',
        class: 'male-btn'
    }
])

export const campaign_types = ref([
    {
        value: 'private',
        label: 'Private',
        icon: 'private',
        description: 'Select the influencers you would like to collaborate with',
        disabled: false
    },
    {
        value: 'public',
        label: 'Public',
        icon: 'public',
        description: 'Publish your campaign to receive influencers applications',
        disabled: true
    }
])

export const campaign_objectives = ref([
    {
        value: 'traffic',
        label: 'Traffic',
        icon: 'traffic',
    },
    {
        value: 'brand-awareness',
        label: 'Brand Awareness',
        icon: 'brand-awareness',
    },
    {
        value: 'product-launch',
        label: 'Product launch',
        icon: 'product-launch',
    },
    {
        value: 'store-opening',
        label: 'Store opening',
        icon: 'store-opening',
    },
    {
        value: 'engagement',
        label: 'Engagement',
        icon: 'engagement',
    },
    {
        value: 'purchases-sales',
        label: 'Purchases/Sales',
        icon: 'purchases-sales',
    },
    {
        value: 'lead-generation',
        label: 'Lead generation',
        icon: 'lead-generation',
    },
    {
        value: 'customer-loyalty',
        label: 'Customer loyalty',
        icon: 'customer-loyalty',
    },
    {
        value: 'app-install',
        label: 'App install',
        icon: 'app-install',
    },
])


export const campaign_objectives_colored = ref([
    {
        value: 'traffic',
        label: 'Traffic',
        icon: 'traffic',
    },
    {
        value: 'brand-awareness',
        label: 'Brand Awareness',
        icon: 'brand-awareness',
    },
    {
        value: 'product-launch',
        label: 'Product launch',
        icon: 'product-launch',
    },
    {
        value: 'store-opening',
        label: 'Store opening',
        icon: 'store-opening',
    },
    {
        value: 'engagement',
        label: 'Engagement',
        icon: 'engagement',
    },
    {
        value: 'purchases-sales',
        label: 'Purchases/Sales',
        icon: 'purchases-sales',
    },
    {
        value: 'lead-generation',
        label: 'Lead generation',
        icon: 'lead-generation',
    },
    {
        value: 'customer-loyalty',
        label: 'Customer loyalty',
        icon: 'customer-loyalty',
    },
    {
        value: 'app-install',
        label: 'App install',
        icon: 'app-install',
    },
])

export const ages = [
    /* {
        value: '12-18',
        label: '12-18',
        icon: '12_18'
    }, */
    {
        value: '18-25',
        label: '18-25',
        icon: '18_25'
    },
    {
        value: '25-35',
        label: '25-35',
        icon: '25_35'
    },
    {
        value: '35-45',
        label: '35-45',
        icon: '35_45'
    },
    {
        value: '45plus',
        label: '45+',
        icon: '45plus'
    }
]

export const content_types_old = [
    {
        value: 'post-photo',
        label: 'Post photo',
        icon: 'post-photo'
    },
    {
        value: 'post-reel',
        label: 'Post reel',
        icon: 'post-reel'
    },
    {
        value: 'organic-story',
        label: 'Organic story',
        icon: 'organic-story'
    },
    {
        value: 'unboxing',
        label: 'Unboxing',
        icon: 'unboxing'
    },
    {
        value: 'event-coverage',
        label: 'Event coverage',
        icon: 'event-coverage'
    },
    {
        value: 'boutique-tour',
        label: 'Boutique tour',
        icon: 'boutique-tour'
    },
    {
        value: 'play-to-win',
        label: 'Play-to-win',
        icon: 'play-to-win'
    }
]

export const followersCount = [
    {
        value: '[0,50000]',
        label: 'Nano-Micro [0 - 50K] <span style="color: #dbc02d">$</span>',
        shortLabel: 'Nano-Micro [0 - 50K]',
        typeLabel: 'Nano-Micro',
        intervalLabel: '[0 - 50K]',
        intervalLabelNT: '0 - 50K',
    },
    {
        value: '[50000,300000]',
        label: 'Mid-Tier [50k - 300K] <span style="color: #dbc02d">$$</span>',
        shortLabel: 'Mid-Tier [50k - 300K]',
        typeLabel: 'Mid-Tier',
        intervalLabel: '[50k - 300K]',
        intervalLabelNT: '50k - 300K',

    },
    {
        value: '[300000,1000000]',
        label: 'Macro [300K - 1M] <span style="color: #dbc02d">$$$</span>',
        shortLabel: 'Macro [300K - 1M]',
        typeLabel: 'Macro',
        intervalLabel: '[300K - 1M]',
        intervalLabelNT: '300K - 1M',

    },
    {
        value: '[1000000,900000000]',
        label: 'Mega [1M+] <span style="color: #dbc02d">$$$$</span>',
        shortLabel: 'Mega [1M+]',
        typeLabel: 'Mega',
        intervalLabel: '[1M+]',
        intervalLabelNT: '1M+',

    },

]

export const followersCountForDropDown = [
    {
        value: [0, 50000],
        label: '0 - 50K',
    },
    {
        value: [50000, 300000],
        label: '50k - 300K',
    },
    {
        value: [300000, 1000000],
        label: '300K - 1M',
    },
    {
        value: [1000000, 900000000],
        label: '1M+',
    },
]

export const engagementRates = [
    {
        value: '[0.5,1]',
        label: '0% - 1%'
    },
    {
        value: '[1,3]',
        label: '1% - 3%'
    },
    {
        value: '[3,6]',
        label: '3% - 6%'
    },
    {
        value: '[6,10]',
        label: '6% - 10%'
    },
    {
        value: '[10,100000]',
        label: '10% +'
    }
]
export const engagementRatesForDropDown = [
    {
        value: [0.5, 1],
        label: '0% - 1%'
    },
    {
        value: [1, 3],
        label: '1% - 3%'
    },
    {
        value: [3, 6],
        label: '3% - 6%'
    },
    {
        value: [6, 10],
        label: '6% - 10%'
    },
    {
        value: [10, 100000],
        label: '10% +'
    }
]

export const paymentMethods = [
    {
        value: 'money',
        label: 'Money'
    },
    {
        value: 'service',
        label: 'Service'
    },
    {
        value: 'money/service',
        label: 'Money & Service'
    },
]

export const personReached = [
    {
        value: '[0,5000]',
        label: '0 - 5k'
    },
    {
        value: '[5000,10000]',
        label: '5K - 10k'
    },
    {
        value: '[10000,50000]',
        label: '10K - 50k'
    },
    {
        value: '[50000,100000]',
        label: '50K - 100k'
    },
    {
        value: '[100000,100000000]',
        label: '100K+'
    }
]

export const colors = ref([
    {
        value: '#6B5DD5',
        label: 'Purple',
    },
    {
        value: '#03224c',
        label: 'Blue',
    },
    {
        value: '#D948CA',
        label: 'Pink',
    },
    {
        value: '#008009',
        label: 'Green',
    },
    {
        value: '#C20417',
        label: 'Red',
    },
    {
        value: '#DB4900',
        label: 'Orange',
    }
])

export const hours = ref([
    {
        value: '00:00:00',
        label: '00:00 AM',
    },
    {
        value: '00:30:00',
        label: '00:30 AM',
    },
    {
        value: '01:00:00',
        label: '01:00 AM',
    },
    {
        value: '01:30:00',
        label: '01:30 AM',
    },
    {
        value: '02:00:00',
        label: '02:00 AM',
    },
    {
        value: '02:30:00',
        label: '02:30 AM',
    },
    {
        value: '03:00:00',
        label: '03:00 AM',
    },
    {
        value: '03:30:00',
        label: '03:30 AM',
    },
    {
        value: '04:00:00',
        label: '04:00 AM',
    },
    {
        value: '04:30:00',
        label: '04:30 AM',
    },
    {
        value: '05:00:00',
        label: '05:00 AM',
    },
    {
        value: '05:30:00',
        label: '05:30 AM',
    },
    {
        value: '06:00:00',
        label: '06:00 AM',
    },
    {
        value: '06:30:00',
        label: '06:30 AM',
    },
    {
        value: '07:00:00',
        label: '07:00 AM',
    },
    {
        value: '07:30:00',
        label: '07:30 AM',
    },
    {
        value: '08:00:00',
        label: '08:00 AM',
    },
    {
        value: '08:30:00',
        label: '08:30 AM',
    },
    {
        value: '09:00:00',
        label: '09:00 AM',
    },
    {
        value: '09:30:00',
        label: '09:30 AM',
    },
    {
        value: '10:00:00',
        label: '10:00 AM',
    },
    {
        value: '10:30:00',
        label: '10:30 AM',
    },
    {
        value: '11:00:00',
        label: '11:00 AM',
    },
    {
        value: '11:30:00',
        label: '11:30 AM',
    },
    {
        value: '12:00:00',
        label: '12:00 PM',
    },
    {
        value: '13:00:00',
        label: '01:00 PM',
    },
    {
        value: '13:30:00',
        label: '01:30 PM',
    },
    {
        value: '14:00:00',
        label: '02:00 PM',
    },
    {
        value: '14:30:00',
        label: '02:30 PM',
    },
    {
        value: '15:00:00',
        label: '03:00 PM',
    },
    {
        value: '15:30:00',
        label: '03:30 PM',
    },
    {
        value: '16:00:00',
        label: '04:00 PM',
    },
    {
        value: '16:30:00',
        label: '04:30 PM',
    },
    {
        value: '17:00:00',
        label: '05:00 PM',
    },
    {
        value: '17:30:00',
        label: '05:30 PM',
    },
    {
        value: '18:00:00',
        label: '06:00 PM',
    },
    {
        value: '18:30:00',
        label: '06:30 PM',
    },
    {
        value: '19:00:00',
        label: '07:00 PM',
    },
    {
        value: '19:30:00',
        label: '07:30 PM',
    },
    {
        value: '20:00:00',
        label: '08:00 PM',
    },
    {
        value: '20:30:00',
        label: '08:30 PM',
    },
    {
        value: '21:00:00',
        label: '09:00 PM',
    },
    {
        value: '21:30:00',
        label: '09:30 PM',
    },
    {
        value: '22:00:00',
        label: '10:00 PM',
    },
    {
        value: '22:30:00',
        label: '10:30 PM',
    },
    {
        value: '23:00:00',
        label: '11:00 PM',
    },
    {
        value: '23:30:00',
        label: '11:30 PM',
    },
])


export const shortlist_influencer_infos = [
    {
        value: 'story-set',
        label: 'Prix 1 Vague de stories',
    },
    {
        value: 'story',
        label: 'Prix 1 Story',
    },
    {
        value: 'event-coverage',
        label: 'Prix 1 Couverture event en stories',
    },
    {
        value: 'unboxing',
        label: 'Prix 1 Unboxing stories',
    },
    {
        value: 'post-reel',
        label: 'Prix 1 Reel',
    },
    {
        value: 'passage-magasin',
        label: 'Prix Passage en magasin',
    },
    {
        value: 'fixed-price',
        label: 'Prix Forfaitaire',
    },
    {
        value: 'tiktok-video',
        label: 'Prix 1 Tiktok',
    },
]

export const content_types = [
    {
        value: 'story-set',
        label: 'Prix 1 Vague de stories',
        icon: 'story-set'
    },
    {
        value: 'story',
        label: 'Prix 1 Story',
        icon: 'story'
    },
    {
        value: 'event-coverage',
        label: 'Prix 1 Couverture event en stories',
        icon: 'event-coverage',
    },
    {
        value: 'unboxing',
        label: 'Prix 1 Unboxing stories',
        icon: 'unboxing',
    },
    {
        value: 'post-reel',
        label: 'Prix 1 Reel',
        icon: 'post-reel',
    },
    {
        value: 'passage-magasin',
        label: 'Passage en magasin',
        icon: 'passage-magasin',
    },
    {
        value: 'fixed-price',
        label: 'Prix Forfaitaire',
        icon: 'fixed-price',
    },
    {
        value: 'tiktok-video',
        label: 'Prix 1 Tiktok',
        icon: 'tiktok-video',
    },
]

export const delivrables = [
    {
        value: 'story-set',
        label: 'Vague de stories',
        icon: 'story-set'
    },
    {
        value: 'story',
        label: 'Une story',
        icon: 'story'
    },
    {
        value: 'event-coverage',
        label: 'Couverture event en stories',
        icon: 'event-coverage',
    },
    {
        value: 'unboxing',
        label: 'Unboxing stories',
        icon: 'unboxing',
    },
    {
        value: 'post-reel',
        label: 'Reel',
        icon: 'post-reel',
    },
    {
        value: 'passage-magasin',
        label: 'Passage en magasin',
        icon: 'passage-magasin',
    },
    {
        value: 'fixed-price',
        label: 'Prix Forfaitaire',
        icon: 'fixed-price',
    },
    {
        value: 'tiktok-video',
        label: 'Tiktok Video',
        icon: 'tiktok-video',
    },
]

export const content_media_kpis = [
    {
        value: 'avg-story-views',
        label: 'Average story views',
        icon: 'avg-story-views'
    },
    {
        value: 'link-clicks',
        label: 'Total Link clicks ',
        icon: 'link-clicks',
    },
    {
        value: 'tag-clicks',
        label: 'Total Tag clicks',
        icon: 'tag-clicks',
    },
    {
        value: 'views',
        label: 'Total Views',
        icon: 'views',
    },
    {
        value: 'likes',
        label: 'Total Likes',
        icon: 'likes',
    },
    {
        value: 'comments',
        label: 'Total Comments',
        icon: 'comments',
    },
    {
        value: 'shares',
        label: 'Total Shares',
        icon: 'shares',
    },
    {
        value: 'saves',
        label: 'Total Saves',
        icon: 'saves',
    },
    {
        value: 'total-interactions',
        label: 'Total interactions',
        icon: 'total-interactions',
    },
    {
        value: 'manually-added-kpi',
        label: 'Another kpi',
        icon: 'manually-added-kpi',
    },
]


export const advertiser_platform_primary_goals = [
    {
        value: 'Manage and organize influencer marketing campaigns',
        label: 'Manage and organize influencer marketing campaigns',
    },
    {
        value: 'Discover new influencers',
        label: 'Discover new influencers',
    },
    {
        value: 'Have advanced analytics on influencers',
        label: 'Have advanced analytics on influencers',
    },
    {
        value: 'Track and analyze influencer performance',
        label: 'Track and analyze influencer performance',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]


export const advertiser_primary_campaign_objectives = [
    {
        value: 'Building Brand Awareness',
        label: 'Building Brand Awareness',

    },
    {
        value: 'Event Promotion',
        label: 'Event Promotion',
    },
    {
        value: 'Driving Traffic',
        label: 'Driving Traffic',
    },
    {
        value: 'Launching a Product or Store',
        label: 'Launching a Product or Store',
    },
    {
        value: 'Boosting Engagement and Customer Loyalty',
        label: 'Boosting Engagement and Customer Loyalty',
    },
    {
        value: 'Generating Leads and Increasing Sales/Purchases',
        label: 'Generating Leads and Increasing Sales/Purchases',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]


export const advertiser_occupations = [
    {
        value: 'Marketing Manager',
        label: 'Marketing Manager',

    },
    {
        value: 'Account Manager',
        label: 'Account Manager',
    },
    {
        value: 'Social Media Manager',
        label: 'Social Media Manager',
    },
    {
        value: 'Brand Manager',
        label: 'Brand Manager',
    },
    {
        value: 'CEO/Founder',
        label: 'CEO/Founder',
    },
    {
        value: 'Trainee',
        label: 'Trainee',
    },
    {
        value: 'Student',
        label: 'Student',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]

export const company_sizes = [
    {
        value: '1-10 employees',
        label: '1-10 employees',

    },
    {
        value: '11-50 employees',
        label: '11-50 employees',
    },
    {
        value: '51-200 employees',
        label: '51-200 employees',
    },
    {
        value: '201-500 employees',
        label: '201-500 employees',
    },
    {
        value: '501-1000 employees',
        label: '501-1000 employees',
    },
    {
        value: '1001+ employees',
        label: '1001+ employees',
    },
]

export const advertiser_influencer_marketing_experience = [
    {
        value: 'Beginner',
        label: 'Beginner',

    },
    {
        value: 'Led Some Campaigns',
        label: 'Led Some Campaigns',
    },
    {
        value: 'Expert',
        label: 'Expert',
    },
]

export const advertiser_campaigns_influencer_management_challenges = [
    {
        value: 'Finding the right influencers',
        label: 'Finding the right influencers',

    },
    {
        value: 'Managing multiple campaigns',
        label: 'Managing multiple campaigns',
    },
    {
        value: 'Tracking performance metrics',
        label: 'Tracking performance metrics',
    },
    {
        value: 'Communication with influencers',
        label: 'Communication with influencers',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]


export const influencer_content_creating_experience = [
    {
        value: 'Just Starting Out',
        label: 'Just Starting Out',

    },
    {
        value: 'Growing My Community',
        label: 'Growing My Community',
    },
    {
        value: 'Already monetizing',
        label: 'Already monetizing',
    },
    {
        value: 'Looking to scale',
        label: 'Looking to scale',
    },
]

export const influencer_platform_primary_goals = [
    {
        value: 'Connect with Brands',
        label: 'Connect with Brands',

    },
    {
        value: 'Grow My Audience',
        label: 'Grow My Audience',
    },
    {
        value: 'Monetize My Content',
        label: 'Monetize My Content',
    },
    {
        value: 'Learn and Improve',
        label: 'Learn and Improve',
    },
    {
        value: 'Have in-depth analytics on my account',
        label: 'Have in-depth analytics on my account',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]

export const creator_next_steps = [
    {
        value: 'Launch a New Content Series',
        label: 'Launch a New Content Series',
    },
    {
        value: 'Partner with More Brands',
        label: 'Partner with More Brands',
    },
    {
        value: 'Increase My Follower Count',
        label: 'Increase My Follower Count',
    },
    {
        value: 'Improve Engagement',
        label: 'Improve Engagement',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]