
import {
  defineComponent,
  ref,
  reactive,
  watch,
  Ref,
  computed,
  onMounted,
  onBeforeMount,
} from "vue";
import { useStore } from "vuex";
import DeepDiff from "deep-diff";
import {
  genders,
  ages,
  countries,
  followersCount,
  engagementRates,
  personReached,
} from "@/core/common/steps";
import axios from "@/core/services/api";
import Multiselect from "@vueform/multiselect";
// import FeedBack from "@/components/feedback/FeedbackPopup.vue";
import { set } from "lodash";
import { getConfigValue } from "@/core/config";

export default defineComponent({
  name: "SearchFilters",
  components: {
    Multiselect,
    // FeedBack,
  },
  setup() {
    const store = useStore();
    const { state } = store;
    const searchFilters = ref(state.stepper.searchFilters);
    const selectedCities = ref(state.stepper.searchFilters.city);
    const dropdownCities: Ref<any[]> = ref([]);
    const cities = reactive({});
    const formattedAges: Ref<any[]> = ref([]);
    const colors = ref([
      "#979797"
    ]);

    const isFeedbackActive = getConfigValue("FEEDBACK") === 'true'

    const platforms = ref([
      {
        value: "instagram",
        label: "Instagram",
      },
      {
        value: "tiktok",
        label: "Tik Tok",
      },
    ]);

    const categories = computed(() =>
      store.state.global.categories.map((cat) => {
        return {
          value: cat.category_id,
          label: cat.label,
        };
      })
    );

    const filters = ref({
      platform: searchFilters.value.platform,
      categories: searchFilters.value.categories,
      gender: searchFilters.value.gender,
      age: searchFilters.value.age,
      residence: searchFilters.value.residence,
      origin: searchFilters.value.origin,
      followersCount: searchFilters.value.followersCount,
      engagementRate: searchFilters.value.engagementRate,
      personReached: searchFilters.value.personReached,
      city: selectedCities.value,
      order_by: searchFilters.value.order_by,
    });

    onMounted(() => {
      getAges();
    });

    onBeforeMount(() => {
      loadCities();
    });

    watch(
      () => filters.value.residence,
      () => {
        loadCities();
      }
    );

    const loadCities = () => {
      dropdownCities.value = [];
      if (filters.value.residence) {
        filters.value.city = {};
        filters.value.residence.forEach((origin: string) => {
          axios
            .get(`/country/states/${origin}`)
            .then((res) => {
              if (res.status === 200) {
                const states: any[] = res.data.data.map((state: any) => {
                  return {
                    label: state.name,
                    value: state.name,
                  };
                });
                return states;
              }
            })
            .then((states) => {
              set(cities, origin, states);
            });
        });
        filters.value.city = searchFilters.value.city;
      }
    };

    const getAges = () => {
      if (filters.value.gender && filters.value.gender.length) {
        formattedAges.value = [];
        filters.value.gender.forEach((gender: any) => {
          const _gender = gender === "F" ? "woman" : "man";
          formattedAges.value.push({
            label: _gender,
            options: ages.map((age) => {
              return {
                value: `${_gender}-${age.value}`,
                label: age.label,
                icon: `${_gender}_${age.value}`,
              };
            }),
          });
        });
      }
    };

    watch(
      () => filters.value.gender,
      () => {
        getAges();
      },
      {
        deep: true,
      }
    );

    watch(
      () => filters.value,
      () => {
        store.commit("stepper/storeSearchFilters", filters.value);
      },
      {
        deep: true,
      }
    );

    const resetFilters = () => {
      store.commit("stepper/resetState");
    };

    const searchHandler = () => {
      const difference = DeepDiff.diff(
        { ...filters.value },
        store.state.stepper.searchHistory
      );
      if (
        typeof difference !== "undefined" &&
        difference?.length &&
        difference?.length > 0
      ) {
        store.commit("stepper/storeSearchHistory", { ...filters.value });
      }
    };

    const refindHandler = () => {
      store.commit("stepper/handleSearch", false);
    };

    const selectCity = (items, residence) => {
      set(filters.value.city, residence, items);
    };

    const changeStyleFilterSearch = () => {
      if (
        document.getElementById("filter-form")!.style.display !== "none" &&
        document.getElementById("filter-form")!.style.display !== "block"
      ) {
       // console.log("make filters display block");
        document.getElementById("filter-form")!.style.display = "block";
        document.getElementById("reset-filters")!.style.display = "block";
      } else if (
        document.getElementById("filter-form")!.style.display === "none"
      ) {
      //  console.log("make filters display block");
        document.getElementById("filter-form")!.style.display = "block";
        document.getElementById("reset-filters")!.style.display = "block";
      }
      else if (document.getElementById("filter-form")!.style.display === "block") {
      //  console.log("make filters display none");

        document.getElementById("filter-form")!.style.display = "none";
        document.getElementById("reset-filters")!.style.display = "none";
      }
    };

    return {
      genders,
      ages,
      countries,
      followersCount,
      engagementRates,
      personReached,
      store,
      platforms,
      categories,
      formattedAges,
      filters,
      dropdownCities,
      cities,
      colors,
      searchHandler,
      selectCity,
      resetFilters,
      refindHandler,
      changeStyleFilterSearch,
      isFeedbackActive,
    };
  },
});
